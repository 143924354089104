const apiPath = {
  // 로그인
  PUBLIC_KEY: '/api/auth/v1/public-key',
  LOGIN: '/api/auth/v1/login',
  MAF_LOGIN: '/api/auth/v1/mfa',
  LOGOUT: '/api/auth/v1/logout',
  // DECRYPT: '/api/auth/v1/test_decrypt',

  // ++ Service
  // 회원관리
  USER_LIST: '/api/user/v1/users', // 목록
  // USER_REGISTER: '/api/user/v1/user', // 생성, 수정
  USER_DETAIL: '/api/user/v1/user/%s', // 상세
  // USER_DELETE: '/api/user/v1/user', // 삭제
  // USER_LOCK: '/api/user/v1/user/%s/locked', // 잠금
  // USER_UNLOCK: '/api/user/v1/user/%s/unlocked', // 잠금해제
  
  // 멤버십관리
  MEMBERSHIP_PAYMENTS_LIST: '/api/membership/v1/user_memberships', //모든 구매 내역 페이징
  MEMBERSHIP_PAYMENTS_USER_ID: '/api/membership/v1/user_memberships/user_id/%s', //user_id로 검색 [user_id]
  MEMBERSHIP_PAYMENTS_MEMBERSHIP_ID: '/api/membership/v1/user_memberships/membership_id/%s', //구매한 멤버쉽 ID로 검색[membershipId]
  MEMBERSHIP_PAYMENTS_PURCHASE_METHOD_TYPE: '/api/membership/v1/user_memberships/payment_method_type/%s', //결제타입으로 검색[paymentMethodType]
  MEMBERSHIP_PAYMENTS_CANCEL: '/api/membership/v1/user_membership/cancel', //구매 취소(환불은 수동)
  MEMBERSHIP_JOIN: '/api/membership/v1/user_membership', //멤버십 추가
  MEMBERSHIP_ID_LIST: '/papi/membership/v1/memberships', //멤버십 종류 목록

  // 포인트 지급관리
  POINT_LOAD: '/api/reward/v1/load', // 포인트 로드
  POINT_CONFIRM: '/api/reward/v1/confirm', // 포인트 지급 실행
  POINT_TRANSACTION_SHEETNAME: '/api/reward/v1/transaction/by-sheetname', // 포인트 지급내역조회 구글시트이름으로
  POINT_TRANSACTION_ID: '/api/reward/v1/transactionIds/%s', // 포인트 지급내역조회 거래ID [transactionId]

  // 포인트 개별지급관리
  POINT_INDIVIDUAL_READY: '/api/point/v1/ready', // 포인트 지급 준비
  POINT_INDIVIDUAL_LOAD: '/api/point/v1/sheet/load', // 엑셀기반 포인트 지급 준비
  POINT_INDIVIDUAL_CONFIRM: '/api/point/v1/transaction-id/%s/charge', // 포인트 지급 실행 [transactionId]

  // 입금이체 은행관리
  BANK_LIST: '/api/firfin_bank/v1/banks', // 현재 퍼핀에서 사용하는 입금이체 은행 목록
  BANK_CHANGE: '/api/firfin_bank/v1/bank/main/%s', // 해당 id를 메인으로 설정하고, 나머지는 Disable 시키는 기능 [bankId]

  // 바코드 카테고리 관리
  BARCODE_CATEGORY_LIST: '/api/barcode/v1/category/list', // 바코드 카테고리 목록
  BARCODE_CATEGORY_REGISTER: '/api/barcode/v1/category', // 바코드 카테고리 등록
  BARCODE_CATEGORY_MODIFY: '/api/barcode/v1/category/%s', // 바코드 카테고리 수정 [categoryUid]
  BARCODE_CATEGORY_DETAIL: '/api/barcode/v1/category/%s/details', // 바코드 카테고리 상세 [categoryUid]
  // 바코드 브랜드 관리
  BARCODE_BRAND_LIST: '/api/barcode/v1/brand/list', // 바코드 브랜드 목록
  BARCODE_BRAND_REGISTER: '/api/barcode/v1/brand', // 바코드 브랜드 등록
  BARCODE_BRAND_MODIFY: '/api/barcode/v1/brand/%s/category/%s', // 바코드 브랜드 수정 [brandCode,categoryUid]
  BARCODE_BRAND_DETAIL: '/api/barcode/v1/brand/%s/details', // 바코드 브랜드 상세 [brandUid]
  // 바코드 카테고리 브랜드 맵핑 관리
  BARCODE_INFO_LIST: '/api/barcode/v1/mapper/info/list', // 바코드 카테고리 브랜드 맵핑 정보 목록
  BARCODE_CATEGORY_POSITION: '/api/barcode/v1/category/%s/seq', // 바코드 카테고리 순서변경 [categoryUid]
  BARCODE_BRAND_POSITION: '/api/barcode/v1/brand/%s/category/%s/seq', // 바코드 브랜드 순서변경 [brandCode, categoryUid]
  // BARCODE_MAPPER_LIST: '/api/barcode/v1/mapper/list', // 바코드 카테고리 브랜드 맵핑 목록
  // BARCODE_MAPPER_REGISTER: '/api/barcode/v1/mapper', // 바코드 카테고리 브랜드 맵핑 등록
  // BARCODE_MAPPER_MODIFY: '/api/barcode/v1/mapper/%s', // 바코드 카테고리 브랜드 맵핑 수정 [mapperUid]
  // BARCODE_MAPPER_DETAIL: '/api/barcode/v1/transaction/history/%s', // 바코드 카테고리 브랜드 맵핑 상세 [firfinUserUid]

  // ** App
  // dashboard
  SCHEDULE_LIST: '/api/memo/v1/memo',

  // ** Cs
  // 회원조회 --
  // 회원정보로 조회
  CS_USER_BY_PHONE: '/api/cs/user/v1/phone/%s', // 전화번호로 조회 [phone]
  CS_USER_BY_CARD_NUMBER: '/api/cs/user/v1/card_no/%s', // 카드번호 조회 [cardNo]
  CS_USER_BY_UID: '/api/cs/user/v1/firfin_user_uid/%s', // uid로 조회 [uid]
  CS_USER_BY_ID: '/api/cs/user/v1/firfin_id/%s', // id로 조회 [id]
  CS_USER_BY_PAY_ID: '/api/cs/user/v1/pay_id/%s', // pay id로 조회 [payId]
  CS_USER_BY_VIRTUAL_ACCOUNT_NUMBER: '/api/cs/user/v1/virtual_account/%s', // 가상계좌번호로 조회 [accountNumber]
  // 은행정보로 조회
  CS_BANK_LIST:'/api/cs/account/v1/bank_companies', // 은행 목록
  CS_USER_BY_BANK: '/api/cs/account/v1/account/%s/%s', // 은행정보로 조회 [bankCompanyId, bankAccount]
  // 결제정보로 조회
  CS_USER_BY_APPROVED: '/api/cs/card/v1/card-transaction/approved-number/%s/date/%s', // 결제정보로 조회 [approveNumber,date]

  // 회원조회 상세 --
  // 회원 기본정보
  CS_DISCARD_SAVINGS: '/api/cs/savings/v1/discard/%s/%s', // 저금통 강제해지 [firfinUserUid, savingsUid]
  CS_REFUND_TO_LEADER: '/api/cs/refund/v1/refund_to_leader', // 리더에게 머니 반환
  // 카드 취소/해지
  CS_CARD_CANCEL: '/api/cs/card/v1/issue-cancel/%s/%s', // 카드 취소 [applicationUid, ownerUid]
  CS_CARD_UNREGISTER: '/api/cs/card/v1/unregister/%s', // 카드 해지 [ownerUid]
  CS_CARD_SUSPEND: '/api/cs/card/v1/admin/suspend', // 카드정지
  CS_CARD_SUSPEND_CANCEL: '/api/cs/card/v1/admin/suspend/cancel', // 카드정제해제
  CS_CARD_UPDATE_FREE_CANCEL: '/api/cs/card/v1/application/change/free', // 해지된 카드 무료발급상태로 변경
  // 카드 신청서
  CS_CARD_DESIGN_CHAGNE: '/api/cs/card/v1/change/design', // 카드 디자인 변경
  // 카드 신청서 내역
  CS_CARD_APPLICATIONS: '/api/cs/card/v1/card/applications/%s', // 카드 신청서 조회 [firfinUserUid]
  // 카드 배송 정보
  CS_CARD_DELIVERY_REFRESH: '/api/cs/card/v1/delivery-info-refresh/%s/%s', // 배달 상태 조회 요청 [applicationUid, firfinUserUid]
  CS_CARD_DELIVERY: '/api/cs/card/v1/delivery/%s', // 배달 상태 정보 [firfinUserUid]
  // 카드 사용 내역
  CS_CARD_HISTORY: '/api/cs/card/v1/transactions/user_id/%s', // 카드 사용 내역 [firfinUserUid]
  CS_CARD_TRANSACTION_CERTIFICATE: '/api/certificate/v1/card-transaction', // 카드 사용 내역 증명서 발급
  // 은행(쿠콘) 입출금 내역
  CS_TRANS_HISTORY: '/api/cs/account/v1/histories/%s', // 은행(쿠콘) 입출금 내역 [payUserId]
  
  // 가상계좌 발급내역
  CS_VIRTUAL_ACCOUNT_TRANSACTIONS: '/api/cs/virtual_account/v1/transactions/%s', // 가상계좌 거래내역 [firfinUserUid]
  // 가상계좌 관리
  CS_VIRTUAL_ACCOUNT_BLOCK: '/api/virtual-account/v1/firfinUserUid/%s/block', // 가상계좌 차단 [firfinUserUid]
  CS_VIRTUAL_ACCOUNT_UNBLOCK: '/api/virtual-account/v1/firfinUserUid/%s/unblock', // 가상계좌 차단해지 [firfinUserUid]

  // 바코드 결제내역
  CS_BARCODE_TRANSACTION_HISTORY: '/api/barcode/v1/transaction/history/%s', // 바코드 결제내역 [firfinUserUid]
  // 지갑 내역
  CS_WALLET_HISTORY: '/api/cs/wallet/v1/wallet/%s', // 지갑 내역 [payUserId]
  // 퍼핀머니 내역
  CS_MONEY_HISTORY: '/api/cs/wallet/v1/money/%s', // 퍼핀머니 내역 [payUserId]
  // 포인트 내역
  CS_POINT_HISTORY: '/api/cs/wallet/v1/point/%s', // 포인트 내역 [payUserId]
  // 송금/결제 한도
  CS_TRANSACTION_LIMIT: '/api/transaction-limit/v1/firfinUserUid/%s/limit', // 송금/결제 한도 [firfinUserUid]
  CS_TRANSACTION_LIMIT_POSSIBLE: '/api/transaction-limit/v1/firfinUserUid/%s/possible', // 송금/결제 한도 사용 가능 금액 조회 [firfinUserUid]
  // 소득 공제
  CS_TAX_HISTORY: '/api/cs/tax-return/v1/child/%s/history',

  // 카드 반송 조회
  CS_CARD_RETURN: '/api/cs/card/v1/return_cards/%s/%s', // 반송조회 [startDate, endDate]
  CS_CARD_PHONE_CHANGE: '/api/cs/card/v1/change-phone-number/%s', // 전화번호 변경 [cardNumber]
  CS_CARD_ADDRESS_CHANGE: '/api/cs/card/v1/change-address/%s', // 주소변경 [cardNumber]
  CS_CARD_REDELIVERY: '/api/cs/card/v1/card-redelivery-or-discard/%s/RE_DELIVERY', // 카드 재발송 [cardNumber]
  CS_CARD_REDELIVERY_CHECK: '/api/cs/card/v1/%s/re-delivery', // 카드 재발송 확인 [cardNumber]
  CS_CARD_DISCARD: '/api/cs/card/v1/card-redelivery-or-discard/%s/DISCARD', // 카드 폐기 [cardNumber]
  CS_CARD_DISCARD_CANCEL: '/api/cs/card/v1/discard/cancel', // KB에서 반송 배치로 온 폐기된 카드와 신청서를 취소로 변경

  // 매입취소
  CS_KB_CARD_PURCHASE_LIST: '/api/cs/purchase/v1/purchase_cancels', // 매입취소 목록
  CS_KB_CARD_PURCHASE_TRANSACTIONS: '/api/cs/purchase/v1/transactions/%s', // 실 거래 내역 [id]
  CS_KB_CARD_PURCHASE_CANCEL: '/api/cs/purchase/v1/cancel/%s', // 매입취소 무시 [id]
  CS_KB_CARD_PURCHASE_RETURN: '/api/cs/purchase/v1/return/%s', // 매입환불 적용 [id]

  // 소득공제 신청자 조회
  CS_TAX_RETURN_CERTIFICATE: '/api/tax_return_certificate/v1/tax_return_certificate', //소득 공제 신청서
  CS_TAX_RETURN_STATUS: '/api/tax_return_certificate/v1/tax_return_certificate/%s/status/%s', //소득 공제 신청서 상태변경 [id, status]

  // 블랙리스트
  CS_BLOCK_APP_USE: '/api/blacklist/v1/firfinUserUid/%s/block-app-use', // 사용자 앱 사용 차단 [firfinUserUid]
  CS_BLOCK_REGISTER: '/api/blacklist/v1/firfinUserUid/%s/block-register', // 사용자 앱 가입 차단 [firfinUserUid]
  CS_BLOCK_HISTORY: '/api/blacklist/v1/search', // 차단 내역 조회
  CS_BLOCK_DELETE: '/api/blacklist/v1/uid/%s/delete', // 차단 내역 삭제 [blacklistUid]

  // ** Store
  // 가맹점 관리
  SOTRE_LIST : '/api/firfinpay/affiliate/v1/affiliates', // 목록
  SOTRE_REGISTER : '/api/firfinpay/affiliate/v1/affiliate', //생성
  SOTRE_DETAIL : '/api/firfinpay/affiliate/v1/affiliate/%s', //상세 [affiliateId]
  SOTRE_MANAGER_LIST : '/api/firfinpay/affiliate/v1/affiliate/%s/users', //담당자정보 목록, 등록 [affiliateId]
  STORE_CONTRACT_LIST :'/api/firfinpay/affiliate/v1/affiliate/edits', // 계약정보 기안 목록
  STORE_CONTRACT_DRAFT :'/api/firfinpay/affiliate/v1/affiliate/%s', // 계약정보 기안 상신 [affiliateId]
  STORE_CONTRACT_APPROVAL :'/api/firfinpay/affiliate/v1/affiliate/edit/approval', // 계약정보 승인

  // 주문 및 결제관리
  ORDER_LIST: '/api/payment/v1/order/list', // 목록
  ORDER_DETAIL: '/api/payment/v1/order/%s/detail', // 상세 [orderId]/api/blacklist/v1/search

  // 개별 거래 조회
  TRADE_LIST: '/api/firfinpay/payments/v1/payments/%s', //목록 [affiliateId]

  // 정산관리
  SETTLEMENT_SET: '/api/settlement/v1/reconciliation', // 목록 가져오기
  SETTLEMENT_LIST: '/api/settlement/v1/reconciliation/%s', // 목록 [affiliateId]

  // ** landing
  // 이벤트 통계 - 출시알람
  SURVEY_SUMMARY: "/api/survey/v1/total",
  SURVEY_DAILY: "/api/survey/v1/daily",
  SURVEY_INFO: "/api/survey/v1/surveys",
  // 레몬레터
  LETTER: "/api/lemonletter/v1/lemonletter",
  LETTER_LIST: "/api/lemonletter/v1/lemonletters",

  // ** setting
  // 직원관리
  ADMIN_LIST: '/api/admin/v1/admins', // 목록
  ADMIN_REGISTER: '/api/admin/v1/admin', // 생성, 수정
  ADMIN_DETAIL: '/api/admin/v1/admin', // 상세
  ADMIN_DELETE: '/api/admin/v1/admin', // 삭제
  ADMIN_LOCK: '/api/admin/v1/admin/%s/locked', // 잠금
  ADMIN_UNLOCK: '/api/admin/v1/admin/%s/unlocked', // 잠금해제
  // 시스템 로그
  SYSTEM_LIST: '/api/logs/v1/list',
  // DB 테이블 조회
  DB_SEARCH_LIST : '/api/info/v1/query',
  // 내정보
  ME: '/api/admin/v1/me',
  CHANGE_PASSWORD: '/api/admin/v1/change_password', // 비밀번호 변경
  MFA_SETTING: '/api/admin/v1/mfa', // mfa 설정

  // 첨부파일
  PRESIGNED_URL: '/api/upload/v1/presigned-url',
  FILE_UPLOAD: '/api/upload/v1/upload',

};

export default apiPath;
  // extension
  // Usage : apiPath.HO_NAME.format(arg1, arg2 ...)
  String.prototype.format = function() {
  return [...arguments].reduce((pattern,value) => pattern.replace(/%s/,value), this);
};